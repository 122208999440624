import { Injectable, inject } from '@angular/core';
import { TechpreNatsService } from './techpre-nats.service';
import { AuthComponent } from 'src/app/auth/auth.component';
import { withoutTokenGuard } from '../guards/without-token.guard';
import { MainComponent } from 'src/app/main/main.component';
import { withTokenGuard } from '../guards/with-token.guard';

@Injectable({
  providedIn: 'root',
})
export class CustomizeService {
  public techpreNatsSrc: TechpreNatsService = inject(TechpreNatsService);
  static data: DataConfig = {
    domain: location.hostname,
    brand: {
      bannerUrl: '',
      opacityOverlayBanner: 1,
      smallLogoUrl: '',
      bigLogoUrl: '',
      faviconUrl: '',
    },
    text: {
      authLogin: {
        title: '¡Bienvenido al ERP!',
        subtitle: `El sistema ERP cuenta con los módulos esenciales para todas las funcionalidades. Si ha olvidado su contraseña, haga clic en el enlace "Recuperar contraseña" para restablecerla.`,
        imgPath: '',
        onlyImg: false,
      },
      authRecover: {
        title: '¡No te preocupes!',
        subtitle: `Ingresa tu correo electrónico y te enviaremos un enlace para restablecer tu contraseña.`,
        imgPath: '',
        onlyImg: false,
      },
      authChange: {
        title: '¡Recuerda siempre esto!',
        subtitle: `Por tu seguridad utiliza un gestor de contraseñas de tu elección.`,
        imgPath: '',
        onlyImg: false,
      },
    },
    theme: {
      dark: {
        font_1: { 'tech-font-1': '"Nunito Sans", sans-serif' },
        user_card_online: { 'tech-user-card-online': '#22c556' },
        user_card_busy: { 'tech-user-card-busy': '#ef4444' },
        user_card_not_visible: { 'tech-user-card-not-visible': '#94a3b8' },
      },
      light: {
        font_1: { 'tech-font-1': '"Nunito Sans", sans-serif' },
      },
    },
    pages: {
      auth: {
        path: 'auth',
        component: AuthComponent,
        canActivate: [withoutTokenGuard],
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'login',
          },
          {
            path: 'login',
            loadComponent: () =>
              import('../../auth/pages/login/login.component').then(
                ({ LoginComponent }) => LoginComponent
              ),

            title: 'Iniciar sesión',
            canActivate: [withoutTokenGuard],
          },
          {
            path: 'recover-password',
            loadComponent: () =>
              import(
                '../../auth/pages/recover-password/recover-password.component'
              ).then(
                ({ RecoverPasswordComponent }) => RecoverPasswordComponent
              ),
            title: 'Recuperar contraseña',
            canActivate: [withoutTokenGuard],
          },
          {
            path: 'change-password',
            loadComponent: () =>
              import(
                '../../auth/pages/change-password/change-password.component'
              ).then(({ ChangePasswordComponent }) => ChangePasswordComponent),
            title: 'Cambiar contraseña',
            canActivate: [withoutTokenGuard],
          },
        ],
      },
      dashboard: {
        path: 'dashboard',
        component: MainComponent,
        canActivate: [withTokenGuard], //
        children: [
          {
            path: '',
            loadComponent: () =>
              import('../../main/pages/home/home.component').then(
                ({ HomeComponent }) => HomeComponent
              ),
            title: 'Home',
          },
          {
            path: 'service-list',
            loadComponent: () =>
              import(
                '../../main/pages/service-list/service-list.component'
              ).then(({ ServiceListComponent }) => ServiceListComponent),
            title: 'Servicios',
          },
          {
            path: 'users',
            loadComponent: () =>
              import('../../main/pages/users/users.component').then(
                ({ UsersComponent }) => UsersComponent
              ),
            title: 'Usuarios',
          },
          {
            path: 'roles',
            loadComponent: () =>
              import('../../main/pages/roles/roles.component').then(
                ({ RolesComponent }) => RolesComponent
              ),
            title: 'Roles y permisos',
          },
          {
            path: 'log',
            loadComponent: () =>
              import('../../main/pages/log/log.component').then(
                ({ LogComponent }) => LogComponent
              ),
            title: 'Logs',
          },
          {
            path: 'currency',
            loadComponent: () =>
              import('../../main/pages/currency/currency.component').then(
                ({ CurrencyComponent }) => CurrencyComponent
              ),
            title: 'Moneda',
          },
          {
            path: 'categories',
            loadComponent: () =>
              import('../../main/pages/categories/categories.component').then(
                ({ CategoriesComponent }) => CategoriesComponent
              ),
            title: 'Categorias',
          },
          {
            path: 'clients',
            loadComponent: () =>
              import('../../main/pages/clients/clients.component').then(
                ({ ClientsComponent }) => ClientsComponent
              ),
            title: 'Clientes',
          },
          {
            path: 'employees',
            loadComponent: () =>
              import('../../main/pages/employees/employees.component').then(
                ({ EmployeesComponent }) => EmployeesComponent
              ),
            title: 'Empleados',
          },
          {
            path: 'invoices',
            loadComponent: () =>
              import('../../main/pages/invoices/invoices.component').then(
                ({ InvoicesComponent }) => InvoicesComponent
              ),
            title: 'Facturas',
          },
        ],
      },

      all: {
        path: '**',
        redirectTo: 'auth',
      },
    },
    modules: [
      {
        icon: 'homeIcon',
        label: 'Inicio',
        children: [
          {
            icon: '',
            label: 'Bienvenida',
            type: 'link',
            routerLink: '/dashboard',
          },
        ],
      },
      {
        icon: 'redIcon',
        label: 'Redes',
        children: [
          {
            icon: '',
            label: 'Bloques de ip',
            type: 'link',
            routerLink: '/dashboard',
          },
          {
            icon: '',
            label: 'Dispositivos',
            type: 'link',
            routerLink: '/dashboard',
          },
        ],
      },
      {
        icon: 'financeIcon',
        label: 'FINANZAS',
        children: [
          {
            icon: '',
            label: 'Proformas',
            type: 'link',
            routerLink: '/dashboard',
          },
          {
            icon: '',
            label: 'Facturas',
            type: 'link',
            routerLink: '/dashboard/invoices',
          },
          {
            icon: '',
            label: 'Cobranzas',
            type: 'link',
            routerLink: '/dashboard',
          },
          {
            icon: '',
            label: 'Pagos',
            type: 'link',
            routerLink: '/dashboard',
          },
        ],
      },
      {
        icon: 'resourcesIcon',
        label: 'Recursos',
        children: [
          {
            icon: '',
            label: 'Empleados',
            type: 'link',
            routerLink: '/dashboard/employees',
          },
          {
            icon: '',
            label: 'Clientes',
            type: 'link',
            routerLink: '/dashboard/clients',
          },
        ],
      },
      {
        icon: 'devIcon',
        label: 'Desarrollo',
        children: [
          {
            icon: '',
            label: 'Backlog',
            type: 'link',
            routerLink: '/dashboard',
          },
        ],
      },
      {
        icon: 'settingsIcon',
        label: 'Configuración',
        children: [
          {
            icon: '',
            label: 'Servicios',
            type: 'link',
            routerLink: '/dashboard/service-list',
          },
          {
            icon: '',
            label: 'Usuarios',
            type: 'link',
            routerLink: '/dashboard/users',
          },
          {
            icon: '',
            label: 'Roles y permisos',
            type: 'link',
            routerLink: '/dashboard/roles',
          },
          {
            icon: '',
            label: 'Logs',
            type: 'link',
            routerLink: '/dashboard/log',
          },
          {
            icon: '',
            label: 'Monedas',
            type: 'link',
            routerLink: '/dashboard/currency',
          },
          {
            icon: '',
            label: 'Categorias',
            type: 'link',
            routerLink: '/dashboard/categories',
          },
        ],
      },
    ],
  };

  constructor() {
    this.loadTheme();
  }

  async getConfig() {
    try {
      await this.techpreNatsSrc.connect();
      // const response: any = await this.techpreNatsSrc.sendRequest({
      //   key: 'auth.verifyDomain',
      //   payload: { domain: CustomizeService.data.domain },
      // });

      // console.log(response);

      // if (response.isValid) {
      //   console.log('Es un domino valido');
      // } else {
      //   console.log('No es un domino valido');
      // }

      // this.techpreNatsSrc.subcribeTo({
      //   key: 'auth.*.este',
      //   opts: {},
      //   cb: (msg) => {
      //     console.log('msg', msg.string(), 1);
      //   },
      // });
      // this.techpreNatsSrc.subcribeTo({
      //   key: 'auth.login',
      //   opts: {},
      //   cb: (msg) => {
      //     console.log('msg', msg.string(), 2);
      //   },
      // });
      // this.techpreNatsSrc.subcribeTo({
      //   key: 'auth.login',
      //   opts: {},
      //   cb: (msg) => {
      //     console.log('msg', msg.string(), 3);
      //   },
      // });
      // this.techpreNatsSrc.subcribeTo({
      //   key: 'auth.login',
      //   opts: {},
      //   cb: (msg) => {
      //     console.log('msg', msg.string(), 4);
      //   },
      // });

      // console.log({ response: response.json() });

      // .then(() => {
      //   console.log('Connected to NATS');
      //   NATS.request('valid_domain', 'techpre').then((response) => {
      //     console.log('response', response?.string());
      //   });
      // });

      const configDb = {};
      this.loadTheme();
    } catch (error) {
      console.error({
        msg: 'Error al cargar configuraciones',
        error,
      });
    }
  }

  loadTheme() {
    const root = document.documentElement;
    const prefersDarkMode = window.matchMedia('(prefers-color: dark)').matches;

    if (prefersDarkMode) {
      for (const key in CustomizeService.data.theme.light) {
        // @ts-ignore-next-line
        const [k, v] = Object.entries(
          CustomizeService.data.theme.light[key]
        ).flat();
        root.style.setProperty(`--${k}`, v as string);
      }
    } else {
      for (const key in CustomizeService.data.theme.dark) {
        // @ts-ignore-next-line
        const [k, v] = Object.entries(
          CustomizeService.data.theme.dark[key]
        ).flat();
        root.style.setProperty(`--${k}`, v as string);
      }
    }
  }
}
