import { Injectable, inject } from '@angular/core';
import { NATS, jwtAuthenticator, headers } from '@assets/lib/nats/nats.js';
import { environment } from '@environments/environment.dev';
import { LocalStorageService } from './local-storage.service';
import { getIpPublic } from '../utils/getIpPublic';

@Injectable({
  providedIn: 'root',
})
export class TechpreNatsService {
  private _localStorageSrc: LocalStorageService = inject(LocalStorageService);

  private _opts = {
    servers: [
      'wss://nats-01.techpre.io',
      // 'wss://nats-02.techpre.io',
      // 'wss://nats-03.techpre.io',
    ],
    authenticator: jwtAuthenticator(
      environment.NATS_CREDS_JWT as string,
      new TextEncoder().encode(environment.NATS_CREDS_SEED)
    ),
    reconnect: true,
    reconnectTimeWait: 1, // Time to wait between reconnections, in ms
    maxReconnectAttempts: -1, // Unlimited reconnection attempts
  };

  constructor() {
    NATS.configure(this._opts);
  }

  async connect() {
    return await NATS.connect().then(() => console.log('Connect server'));
  }

  async sendRequest({ key = '', payload = {}, config = { timeout: 10000 } }) {
    const ip = await getIpPublic();
    const _config = this.validHeaderAuthorization({ ...config, ip });

    console.log(_config);

    const response = await NATS.request(
      key,
      JSON.stringify(payload),
      _config
    ).then((response) => response.json());

    if (response.status === 'error') {
      throw response;
    } else {
      return response;
    }
  }

  subcribeTo({ key = '', opts = {}, cb }) {
    return NATS.subscribe(key, opts, cb);
  }

  validHeaderAuthorization(config: any) {
    const _headers = headers();

    if (config.ip) {
      _headers.append('user-ip-tech', config.ip);
      config.headers = _headers;
    }

    if (
      config?.headers?.authorization ||
      !this._localStorageSrc.get('x-bearer-tech')
    )
      return config;

    _headers.append(
      'x-bearer-tech',
      this._localStorageSrc.get('x-bearer-tech')
    );

    config.headers = _headers;

    return config;
  }
}
